$card-half: 375px;
$end-position: 100vw;
$start-position: 0vw;

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 35%;
    width: 35%;
    max-width: 300px;
    border-radius: 100%;
    pointer-events: none;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    margin-top: 15px;
  }
  
  header h1, h2 {
    margin: 0;
    color: royalblue;
  }

  .App-header h2, .App-header h4 {
      color: white!important;
  }
  
  @keyframes mymove {
    from {left: 0px;}
    to {left: 200px;}
  }
  
  .MuiTypography-h4 {
    font-weight: 500!important;
  }
  
  
  header h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .MuiChip-root {
    background-color: deepskyblue!important;
    color: white!important;
    font-weight: 400;
    margin: 4px!important;
  }
  
  .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color:grey;
    margin-top: 10px
  }
  
  body {
    // background-color: #f4f4f4;
    // overflow-x: hidden;
  }
  
  .card-container {
    display: flex;
    justify-content: center;
  }
  
  .schwabLogo, .q2Logo {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .q2Logo img {
    width: 130px;
    border-radius: 100%;
    border: 1px solid lightgreen;
  }
  
  .schwabLogo img {
    width: 130px;
    border-radius: 100%;
  }
  
  .card-container Card {
    width: 500px;
  }
  
  .MuiCard-root {
    width: 700px;
    height: 425px;
    margin-right: 25px;
    margin-left: 25px;
    margin-top: 20px;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);  
  }
  
  .card-1 {
    display: block;
    overflow-x: hidden;
    position: absolute;
    left: 0%
  }
  
  .card-2 {
    display: block;
    overflow-x: hidden;
    position: absolute;
    left: 105%
  }
  
  .card-3 {
    display: block;
    overflow-x: hidden;
    position: absolute;
    left: 180%;
  }
  
  .card-4 {
    display: block;
    overflow-x: hidden;
    position: absolute;
    left: 255%;
  }
  
  #card-1 {
    left: calc(50vw - $card-half);
    transition: 2s;
  }
  
  #card-2 {
    left: 100vw;
    transition: 2s;
  }
  
  #card-3 {
    left: 1850px;
    transition: 2s;
  }
  
  #card-4 {
    left: 2700px;
    transition: 2s;
  }
  
  .move-to-center {
    display: block;
    position: absolute;
    overflow-x:hidden;
    animation: mymoveToCenter 3s 1;
    left: 30%;
  }
  
  /* .rightFromCenter {
    overflow-x: hidden;
    animation: moveRightFromCenter 3s 1;
    left: 105%;
  }
  
  .leftFromCenter {
    animation: moveLeftFromCenter 3s 1;
    left: -40%;
  }
  
  .centerFromRight {
    animation: moveCenterFromRight 3s 1;
    left: 30%;
  }
  
  .centerFromLeft {
    animation: moveCenterFromLeft 3s 1;
    left: 30%;
  } */
  
  .left {
    overflow-x: hidden;
    position: absolute;
    left: 0%;
  }
  
  .right {
    display: block;
    overflow-x: hidden;
    position: absolute;
    left: 105%;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
  }
  
  .work-container {
    display: flex;
    justify-content: center;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 15px;
  }
  
  .rightButton, .leftButton {
    display: flex;
    align-items: center;
  }
  
  .rightButton {
    margin-left: 20px;
  }
  
  .leftButton {
    margin-right: 20px;
  }
  
  .MuiFab-primary {
    background-color:royalblue!important;
  }
  
  .description {
    font-weight: 400;
  }
  
  .MuiDivider-middle {
    margin-top: 16px!important;
    margin-bottom: 12px!important;
  }
  
  .footer {
    height: 465px!important;
    display: flex;
    justify-content: center;
    align-content: center;
  }
  
  .chipSection {
    margin-top: 8px;
    margin-left: 25px;
    margin-right: 25px;
    
  }
  
  .MuiTypography-h6 {
    line-height: 1.3!important;
  }